export const localizationStrings = {
    fr: {
        home: {
            home: 'Accueil'
        }
    },
    en: {
        home: {
            home: 'Home'
        }
    }
};