var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid-2 flex-center" }, [
    _c("section", { staticClass: "upload--container" }, [
      _c("h2", { staticClass: "title" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.$t("fileNotFoundTitle")) + "\n        "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "upload--widget-container upload--result" }, [
        _c(
          "p",
          {
            staticClass: "badge danger",
            attrs: { role: "status", "aria-live": "polite" }
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("fileNotFoundMessage")) +
                "\n            "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "copy--button button",
            attrs: { id: "copyLink" },
            on: { click: _vm.goToHome }
          },
          [_vm._v(_vm._s(_vm.$t("goToHome")))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }