import '@babel/polyfill';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import { localizationStrings } from './Common/resources';
const App = () => import('./app.vue');
import { routes } from './routes';

Vue.use(VueRouter);
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'fr',
    messages: localizationStrings
});

const router = new VueRouter({
    routes
});

export const eventBus = new Vue();

router.beforeEach((to, from, next) => {
    eventBus.$emit('showing-load-panel', true, to.name);
    next();
});

new Vue({
    el: '#mainApp',
    i18n,
    router,
    render: h => h(App)
});