import Home from './home.vue';
import NotFound from './not-found.vue';

export const routes = [
    {
        path: '/',
        component: Home,
        name: 'Home',
        meta: {
            breadcrumb: 'home.home'
        }
    },
    {
        path: '/NotFound',
        name: 'NotFound',
        component: NotFound,
        meta: {
            breadcrumb: 'home.notFound'
        }
    }
];